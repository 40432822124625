var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fp-select',{attrs:{"label":_vm.$t('partner.participantList.workflowTab.workflow'),"items":_vm.workflowList,"textKey":"Name"},scopedSlots:_vm._u([{key:"element",fn:function(element){return [_vm._v(" "+_vm._s(element.Name)),_c('br'),_vm._v(_vm._s(element.Description)+" ")]}}]),model:{value:(_vm.selectedWorkflow),callback:function ($$v) {_vm.selectedWorkflow=$$v},expression:"selectedWorkflow"}}),(_vm.selectedWorkflow)?_c('div',[_c('b-button',{on:{"click":function($event){return _vm.getWorkflowProgress(_vm.selectedWorkflow.InstanceId)}}},[_c('b-icon',{attrs:{"icon":"arrow-repeat"}}),_vm._v(" "+_vm._s(_vm.$t("base.basic.update"))+" ")],1),(_vm.selectedWorkflowProgress)?_c('div',{staticStyle:{"text-align":"center","margin":"10px 0px"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("partner.participantList.workflowTab.progress"))+": "+_vm._s(( (_vm.selectedWorkflowProgress.TotalExecutedStepWeight / _vm.selectedWorkflowProgress.TotalStepWeight) * 100 ).toFixed(0))+"% ")]),_c('b-progress',{attrs:{"height":"2rem","max":_vm.selectedWorkflowProgress.TotalStepWeight,"value":_vm.selectedWorkflowProgress.TotalExecutedStepWeight}})],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.$t("partner.participantList.workflowTab.currentSteps"))+": "),_vm._l((_vm.currentSteps),function(current){return _c('div',{key:current.ElementId},[_c('table',{staticStyle:{"width":"100%"}},[_c('td',{staticClass:"workflow-element-cell"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                _vm.$t(
                  'partner.participantList.workflowTab.currentInProgress'
                )
              ),expression:"\n                $t(\n                  'partner.participantList.workflowTab.currentInProgress'\n                )\n              ",modifiers:{"hover":true}}],staticClass:"workflow-element current-element"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$enums.WorkflowStep[current.Type].Text))+" ")])]),_vm._l((current.NextSteps),function(next){return _c('td',{key:'workflow-element-' + next.ElementId,staticClass:"workflow-element-cell"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                next.Status == 'Waiting'
                  ? _vm.$t(
                      'partner.participantList.workflowTab.stepWaiting'
                    )
                  : ''
              ),expression:"\n                next.Status == 'Waiting'\n                  ? $t(\n                      'partner.participantList.workflowTab.stepWaiting'\n                    )\n                  : ''\n              ",modifiers:{"hover":true}}],staticClass:"workflow-element"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$enums.WorkflowStep[next.Type].Text))+" ")])])})],2)])})],2),_c('b-button',{on:{"click":_vm.abortWorkflow}},[_vm._v(_vm._s(_vm.$t("base.basic.stop")))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }