<template>
  <div>
    <fp-select
        :label="$t('partner.participantList.workflowTab.workflow')"
        :items="workflowList"
        v-model="selectedWorkflow"
        textKey="Name"
    >
      <template #element="element">
        {{ element.Name }}<br />{{ element.Description }}
      </template>
    </fp-select>
    <div v-if="selectedWorkflow">
      <b-button @click="getWorkflowProgress(selectedWorkflow.InstanceId)">
        <b-icon icon="arrow-repeat"></b-icon> {{ $t("base.basic.update") }}
      </b-button>
      <div
          v-if="selectedWorkflowProgress"
          style="text-align: center; margin: 10px 0px"
      >
        <b>
          {{ $t("partner.participantList.workflowTab.progress") }}:
          {{
            (
                (selectedWorkflowProgress.TotalExecutedStepWeight /
                    selectedWorkflowProgress.TotalStepWeight) *
                100
            ).toFixed(0)
          }}%
        </b>
        <b-progress
            height="2rem"
            :max="selectedWorkflowProgress.TotalStepWeight"
            :value="selectedWorkflowProgress.TotalExecutedStepWeight"
        >
        </b-progress>
      </div>
      <div>
        {{ $t("partner.participantList.workflowTab.currentSteps") }}:
        <div v-for="current in currentSteps" :key="current.ElementId">
          <table style="width: 100%">
            <td class="workflow-element-cell">
              <div
                  v-b-tooltip.hover="
                  $t(
                    'partner.participantList.workflowTab.currentInProgress'
                  )
                "
                  class="workflow-element current-element"
              >
                {{
                  $t($enums.WorkflowStep[current.Type].Text)
                }}
              </div>
            </td>
            <td
                class="workflow-element-cell"
                v-for="next in current.NextSteps"
                :key="'workflow-element-' + next.ElementId"
            >
              <div
                  v-b-tooltip.hover="
                  next.Status == 'Waiting'
                    ? $t(
                        'partner.participantList.workflowTab.stepWaiting'
                      )
                    : ''
                "
                  class="workflow-element"
              >
                {{ $t($enums.WorkflowStep[next.Type].Text) }}
              </div>
            </td>
          </table>
        </div>
      </div>
      <b-button @click="abortWorkflow">{{ $t("base.basic.stop") }}</b-button>
    </div>
  </div>
</template>
<script>
import { WorkflowLogic } from "../../../Logic/Backend/workflow";
export default {
  name: "Workflow",
  props: {
    participant: Object,
  },
  data() {
    return {
      workflowList: null,
      selectedWorkflow: null,
      selectedWorkflowProgress: null,
      currentSteps: null,
    };
  },
  watch: {
    async selectedWorkflow(input) {
      if (input) {
        await this.getWorkflowProgress(input.InstanceId);
      } else {
        this.selectedWorkflowProgress = null;
      }
    },
    async participant() {
      await this.getWorkflowList();
    },
  },
  methods: {
    async getWorkflowList() {
      this.workflowList = [];
      const getWorkflowListResponse =
          await WorkflowLogic.getWorkflowInstanceList({
            Status: this.$enums.WorkflowStatus.InProgress,
            UserId: this.participant.UserId,
            ProjectId: this.$loggedUser.SelectedProject.ProjectId,
          });
      if (!getWorkflowListResponse.Code) {
        this.workflowList = getWorkflowListResponse;
      } else {
        this.$bvToast.toast(getWorkflowListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
                "partner.participantList.workflowTab.errorStartedName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getWorkflowProgress(instanceId) {
      const getWorkflowProgressResponse =
          await WorkflowLogic.getWorkflowProgress(instanceId);
      if (!getWorkflowProgressResponse.Code) {
        this.selectedWorkflowProgress = getWorkflowProgressResponse;
        const temp = [];
        Object.entries(this.selectedWorkflowProgress.NextElements).forEach(
            ([current, next]) => {
              const step = this.selectedWorkflow.Elements[current];
              this.$set(step, "NextSteps", next);
              temp.push(step);
            }
        );
        this.currentSteps = temp;
      } else {
        this.$bvToast.toast(getWorkflowProgressResponse.Message, {
          title: this.$t("base.error"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async abortWorkflow() {
      const abortWorkflowResponse = await WorkflowLogic.abortWorkflow(
          this.selectedWorkflow.InstanceId
      );
      if (!abortWorkflowResponse.Code) {
        this.$bvToast.toast(
            this.$t("requestResponse.workflow.successAbortMessage"),
            {
              title: this.$t("requestResponse.workflow.successAbortTitle"),
              variant: "info",
              solid: true,
            }
        );
        await this.getWorkflowList();
      } else {
        this.$bvToast.toast(abortWorkflowResponse.Message, {
          title: this.$t("requestResponse.workflow.errorAbortMessage"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getWorkflowList();
  },
};
</script>
<style scoped>
.workflow-element-cell {
  display: inline-block;
}
/* Default Workflow element */
.workflow-element {
  line-height: 50px;
  position: relative;
  text-align: center;
  background: var(--eh-primary-2);
  border-right: none;
  border-start-start-radius: 10px;
  border-end-start-radius: 10px;
  margin: 10px;
  padding: 5px 15px;
}
.workflow-element:after,
.workflow-element:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 50%;
  left: 100%;
}
.workflow-element:after {
  bottom: 0;
  background: linear-gradient(
      to right bottom,
      var(--eh-primary-2) 50%,
      transparent 50%
  );
}
.workflow-element:before {
  top: 0;
  background: linear-gradient(
      to right top,
      var(--eh-primary-2) 50%,
      transparent 50%
  );
}
/* Inprogress Workflow element */
.workflow-element.current-element {
  background: lightskyblue;
}
.workflow-element.current-element:after {
  bottom: 0;
  background: linear-gradient(
      to right bottom,
      lightskyblue 50%,
      transparent 50%
  );
}
.workflow-element.current-element:before {
  bottom: 0;
  background: linear-gradient(to right top, lightskyblue 50%, transparent 50%);
}
/* Success Workflow element */
.workflow-element.result-success {
  background: greenyellow;
}
.workflow-element.result-success:after {
  bottom: 0;
  background: linear-gradient(
      to right bottom,
      greenyellow 50%,
      transparent 50%
  );
}
.workflow-element.result-success:before {
  bottom: 0;
  background: linear-gradient(to right top, greenyellow 50%, transparent 50%);
}
/* Failed Workflow element */
.workflow-element.result-failed {
  background: red;
}
.workflow-element.result-failed:after {
  bottom: 0;
  background: linear-gradient(to right bottom, red 50%, transparent 50%);
}
.workflow-element.result-failed:before {
  bottom: 0;
  background: linear-gradient(to right top, red 50%, transparent 50%);
}
</style>